import PopupContainer from "@app/components/popup/PopupContainer";
import {useAppSelector} from "@store/hooks";
import {useEffect, useRef, useState} from "react";
import api from "../../api/admin"
import AdminAPI from "../../api/admin";

interface AddChanelPopupProps {
  onClose: () => void;

}
const AddChannelPopup = ({onClose}: AddChanelPopupProps) => {
  const {token} = useAppSelector(state => state.auth);
  const [channelId, setChannelId] = useState<number>(-1)
  const videoTitleRef = useRef<HTMLInputElement>(null);
  const videoUrlRef = useRef<HTMLInputElement>(null);
  const naverUrlRef = useRef<HTMLInputElement>(null);
  const kakaoUrlRef = useRef<HTMLInputElement>(null);

  const [uploading, setUploading] = useState(false);
  const [channelList, setChannelList] = useState<any[]>([])
  useEffect(() => {
    AdminAPI.getChannelListAll().then((res) => {
      setChannelList(res.data.list)
      setChannelId(res.data.list[0].id)
    })
  }, []);
  const handleCreateNotice = async () => {
    const videoTitle = videoTitleRef.current?.value;
    const videoUrl = videoUrlRef.current?.value;
    const naverUrl = naverUrlRef.current?.value;
    const kakaoUrl = kakaoUrlRef.current?.value;
    if (videoTitle !== undefined && videoUrl !== undefined && naverUrl !== undefined && kakaoUrl !== undefined) {
      AdminAPI.createRestaurant({channelId, videoTitle, videoUrl, naverUrl, kakaoUrl}).then((res) => {
        alert(res.success ? '등록되었습니다' : '등록에 실패했습니다');
        if(res.success) {
          onClose()
        }
      })
    } else {
      alert('값을 입력해주세요')
    }
  };


  return <PopupContainer onClickBackdrop={onClose}>
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">맛집 등록</h4>
          <button onClick={() => onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <div className="modal-body">
          <div className="form-group">
            <label htmlFor="exampleSelectRounded0">채널</label>
            <select className="custom-select rounded-0" id="exampleSelectRounded0" onChange={event => {
              console.log(event.target.value)
              setChannelId(Number(event.target.value))
            }}>
              {
                channelList.map((item) => {
                  return <option key={item.id} value={item.id}>{item.name}</option>
                })
              }
            </select>
          </div>

          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">동영상 URL (Youtube)</label>
            <div className="col-sm-9">
              <input ref={videoUrlRef} type="text" className="form-control" placeholder="URL 입력"/>
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">동영상 제목</label>
            <div className="col-sm-9">
              <input ref={videoTitleRef} type="text" className="form-control" placeholder="제목 입력"/>
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">Naver Map URL</label>
            <div className="col-sm-9">
              <input ref={naverUrlRef} type="text" className="form-control" placeholder="URL 입력"/>
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">Kakao Map URL</label>
            <div className="col-sm-9">
              <input ref={kakaoUrlRef} type="text" className="form-control" placeholder="URL 입력"/>
            </div>
          </div>

        </div>
        <div className="modal-footer justify-content-between">
          <button type="button" className="btn btn-primary" onClick={handleCreateNotice}>등록하기</button>
        </div>
      </div>
    </div>
  </PopupContainer>
}
export default AddChannelPopup;
