import React, {useEffect} from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import Main from '@modules/main/Main';
import Login from '@modules/login/Login';
import Register from '@modules/register/Register';
import ForgetPassword from '@modules/forgot-password/ForgotPassword';
import RecoverPassword from '@modules/recover-password/RecoverPassword';
import {useWindowSize} from '@app/hooks/useWindowSize';
import {calculateWindowSize} from '@app/utils/helpers';
import {useDispatch, useSelector} from 'react-redux';
import {setWindowSize} from '@app/store/reducers/ui';
import Dashboard from '@pages/Dashboard';
import Blank from '@pages/Blank';
import SubMenu from '@pages/SubMenu';
import Profile from '@pages/profile/Profile';

import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import ChannelList from "@pages/ChannelList";
import RestaurantList from "@pages/RestaurantList";

;

const App = () => {
  const windowSize = useWindowSize();
  const screenSize = useSelector((state: any) => state.ui.screenSize);
  const dispatch = useDispatch();

  useEffect(() => {
    const size = calculateWindowSize(windowSize.width);
    if (screenSize !== size) {
      dispatch(setWindowSize(size));
    }
  }, [windowSize]);

  useEffect(() => {

  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<PublicRoute/>}>
          <Route path="/login" element={<Login/>}/>
        </Route>
        <Route path="/register" element={<PublicRoute/>}>
          <Route path="/register" element={<Register/>}/>
        </Route>
        <Route path="/forgot-password" element={<PublicRoute/>}>
          <Route path="/forgot-password" element={<ForgetPassword/>}/>
        </Route>
        <Route path="/recover-password" element={<PublicRoute/>}>
          <Route path="/recover-password" element={<RecoverPassword/>}/>
        </Route>
        <Route path="/" element={<PrivateRoute/>}>
          <Route path="/" element={<Main/>}>

            <Route path="/channel/list" element={<ChannelList/>}/>
            <Route path="/restaurant/list" element={<RestaurantList/>}/>

            <Route path="/sub-menu-2" element={<Blank/>}/>
            <Route path="/sub-menu-1" element={<SubMenu/>}/>
            <Route path="/blank" element={<Blank/>}/>
            <Route path="/profile" element={<Profile/>}/>
            <Route path="/" element={<Dashboard/>}/>
          </Route>
        </Route>
      </Routes>
      <ToastContainer
        autoClose={3000}
        draggable={false}
        position="top-right"
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnHover
      />
    </BrowserRouter>
  );
};

export default App;
