import PopupContainer from "@app/components/popup/PopupContainer";
import {useAppSelector} from "@store/hooks";
import {useEffect, useRef, useState} from "react";
import api from "../../api/admin"
import AdminAPI from "../../api/admin";

interface ChannelInfoProps {
  onClose: () => void;
  restaurantId: number;
}
const ChannelInfoPopup = ({onClose, restaurantId}: ChannelInfoProps) => {
  const {token} = useAppSelector(state => state.auth);
  const titleRef = useRef<HTMLInputElement>(null);
  const urlRef = useRef<HTMLInputElement>(null);
  const thumbRef = useRef<HTMLInputElement>(null);
  const [uploading, setUploading] = useState(false);
  const [data, setData] = useState<any>(null)

  useEffect(function(){
    AdminAPI.getChannel({id: restaurantId}).then(res => {
      setData(res.data);
    })
  },[restaurantId])

  return <PopupContainer onClickBackdrop={onClose}>
    {
      data && <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">맛집 정보</h4>
            <button onClick={() => onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div className="modal-body">
            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">이름</label>
              <div className="col-sm-9">
                <input ref={titleRef} type="text" className="form-control" placeholder="상호명" value={data.name}
                       disabled={true}/>
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">썸네일</label>
              <div className="col-sm-9">
                <img src={data.thumbnailUrl} alt="썸네일" style={{width: '100px', height: '100px'}}/>
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">타입</label>
              <div className="col-sm-9">
                <input ref={titleRef} type="text" className="form-control" placeholder="타입" value={data.type}
                       disabled={true}/>
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">URL</label>
              <div className="col-sm-9">
                <input ref={titleRef} type="text" className="form-control" placeholder="URL" value={data.url}
                       disabled={true}/>
              </div>
            </div>


          </div>
        </div>
      </div>
    }
  </PopupContainer>
}
export default ChannelInfoPopup;
