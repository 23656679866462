import axios from "axios";

const ENDPOINT = process.env.REACT_APP_API_HOST + '/admin';

const AdminAPI = {

  login(params: {id: string, password: string}) {
    return axios.post(ENDPOINT + '/login', params).then(res => res.data);
  },
  getInfo(params: {session: string}) {
    return axios.get(ENDPOINT + '/info', {
      headers: {
        session: params.session
      }
    }).then(res => res.data);
  },
  createRestaurant(params: {
    channelId: number, videoTitle: string, videoUrl: string, naverUrl: string, kakaoUrl: string
  }) {
    return axios.post(ENDPOINT + '/restaurant/create', params).then(res => res.data);
  },
  getRestaurantList(params: {page: number, channelId: number}) {
    return axios.get(ENDPOINT + '/restaurant/list', {
      params
    }).then(res => res.data);
  },
  getRestaurant(params: {id: number}) {
    return axios.get(ENDPOINT + '/restaurant/info', {
      params
    }).then(res => res.data);
  },
  getChannelList(param: {page: number}) {
    return axios.get(ENDPOINT + '/channel/list', {
      params: param
    }).then(res => res.data);
  },
  getChannelListAll() {
    return axios.get(ENDPOINT + '/channel/list/all', {}).then(res => res.data);
  },
  getChannel(params: {id: number}) {
    return axios.get(ENDPOINT + '/channel/info', {
      params
    }).then(res => res.data);
  },
  createChannel(params: {name: string, url: string}) {
    return axios.post(ENDPOINT + '/channel/create', params).then(res => res.data);
  },
  deleteChannel(params: {id: number}) {
    return axios.post(ENDPOINT + '/channel/delete', params).then(res => res.data);
  },
  deleteRestaurant(params: {id: number}) {
    return axios.post(ENDPOINT + '/restaurant/delete', params).then(res => res.data);
  }
};

export default AdminAPI;
