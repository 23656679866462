import PopupContainer from "@app/components/popup/PopupContainer";
import {useAppSelector} from "@store/hooks";
import {useEffect, useRef, useState} from "react";
import api from "../../api/admin"
import AdminAPI from "../../api/admin";

interface RestaurantInfoPopupProps {
  onClose: () => void;
  restaurantId: number;
}
const RestaurantInfoPopup = ({onClose, restaurantId}: RestaurantInfoPopupProps) => {
  const {token} = useAppSelector(state => state.auth);
  const titleRef = useRef<HTMLInputElement>(null);
  const urlRef = useRef<HTMLInputElement>(null);
  const thumbRef = useRef<HTMLInputElement>(null);
  const [uploading, setUploading] = useState(false);
  const [data, setData] = useState<any>(null)

  useEffect(function(){
    AdminAPI.getRestaurant({id: restaurantId}).then(res => {
      setData(res.data);
    })
  },[restaurantId])

  return <PopupContainer onClickBackdrop={onClose}>
    {
      data && <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">맛집 정보</h4>
            <button onClick={() => onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div className="modal-body">
            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">상호</label>
              <div className="col-sm-9">
                <input ref={titleRef} type="text" className="form-control" placeholder="상호명" value={data.name}
                       disabled={true}/>
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">카테고리</label>
              <div className="col-sm-9">
                <input ref={titleRef} type="text" className="form-control" placeholder="카테고리" value={data.category}
                       disabled={true}/>
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">전화번호</label>
              <div className="col-sm-9">
                <input ref={titleRef} type="text" className="form-control" placeholder="전화번호" value={data.phone}
                       disabled={true}/>
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">주소</label>
              <div className="col-sm-9">
                <input ref={titleRef} type="text" className="form-control" placeholder="주소" value={data.address}
                       disabled={true}/>
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">카카오맵 URL</label>
              <div className="col-sm-9">
                <input ref={titleRef} type="text" className="form-control" placeholder="카카오맵 URL" value={data.kakaoUrl}
                       disabled={true}/>
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">카카오맵 평점</label>
              <div className="col-sm-9">
                <input ref={titleRef} type="text" className="form-control" placeholder="카카오맵 평점" value={data.kakaoScore}
                       disabled={true}/>
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">카카오맵 리뷰 수</label>
              <div className="col-sm-9">
                <input ref={titleRef} type="text" className="form-control" placeholder="카카오맵 리뷰 수"
                       value={data.kakaoReviewCount}
                       disabled={true}/>
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">네이버 URL</label>
              <div className="col-sm-9">
                <input ref={titleRef} type="text" className="form-control" placeholder="네이버 URL" value={data.naverUrl}
                       disabled={true}/>
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">네이버 평점</label>
              <div className="col-sm-9">
                <input ref={titleRef} type="text" className="form-control" placeholder="네이버 평점" value={data.naverScore}
                       disabled={true}/>
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">네이버 리뷰 수</label>
              <div className="col-sm-9">
                <input ref={titleRef} type="text" className="form-control" placeholder="네이버 리뷰 수"
                       value={data.naverReviewCount}
                       disabled={true}/>
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">lat</label>
              <div className="col-sm-9">
                <input ref={titleRef} type="text" className="form-control" placeholder="lat" value={data.lat}
                       disabled={true}/>
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">lng</label>
              <div className="col-sm-9">
                <input ref={titleRef} type="text" className="form-control" placeholder="lng" value={data.lng}
                       disabled={true}/>
              </div>
            </div>

          </div>
        </div>
      </div>
    }
  </PopupContainer>
}
export default RestaurantInfoPopup;
