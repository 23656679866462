import React from 'react';
import {Link} from 'react-router-dom';

import styled from 'styled-components';
import {PfDropdown} from '@profabric/react-components';

export const StyledDropdown = styled(PfDropdown)`
  border: none;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  --pf-dropdown-menu-min-width: 18rem;

  .dropdown-item {
    padding: 0.5rem 1rem;
  }

  .text-sm {
    margin-bottom: 0;
  }
  .dropdown-divider {
    margin: 0;
  }
`;

const NotificationsDropdown = () => {

  return (
    <StyledDropdown hideArrow>
      <div slot="button">
        <i className="far fa-bell" />
        <span className="badge badge-warning navbar-badge">15</span>
      </div>
      <div slot="menu">
        <span className="dropdown-item dropdown-header">
          15개의 알림
        </span>
        <div className="dropdown-divider" />
        <Link to="/" className="dropdown-item">
          <i className="fas fa-envelope mr-2" />
          <span>
            새 메시지 : 4개
          </span>
          <span className="float-right text-muted text-sm">
            3분 전
          </span>
        </Link>
        <div className="dropdown-divider" />
        <Link to="/" className="dropdown-item">
          <i className="fas fa-users mr-2" />
          <span>
            친구 요청 : 5개
          </span>
          <span className="float-right text-muted text-sm">
            12시간 전
          </span>
        </Link>
        <div className="dropdown-divider" />
        <Link to="/" className="dropdown-item">
          <i className="fas fa-file mr-2" />
          <span>
            리포트 : 3개
          </span>
          <span className="float-right text-muted text-sm">
            2일 전
          </span>
        </Link>
        <div className="dropdown-divider" />
        <Link to="/" className="dropdown-item dropdown-footer">
          모두 읽음
        </Link>
      </div>
    </StyledDropdown>
  );
};

export default NotificationsDropdown;
