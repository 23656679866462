import PopupContainer from "@app/components/popup/PopupContainer";
import {useAppSelector} from "@store/hooks";
import {useEffect, useRef, useState} from "react";
import api from "../../api/admin"
import AdminAPI from "../../api/admin";

interface AddChanelPopupProps {
  onClose: () => void;

}
const AddChannelPopup = ({onClose}: AddChanelPopupProps) => {
  const {token} = useAppSelector(state => state.auth);
  const titleRef = useRef<HTMLInputElement>(null);
  const urlRef = useRef<HTMLInputElement>(null);
  const thumbRef = useRef<HTMLInputElement>(null);

  const handleCreate = async () => {
    const name = titleRef.current?.value;
    const url = urlRef.current?.value;
    if(name !== undefined && url !== undefined) {
      AdminAPI.createChannel({name, url}).then((res) => {
        alert(res.success ? '등록되었습니다' : '등록에 실패했습니다');
        if(res.success) {
          onClose()
        }
      })
    }else{
      alert('값을 입력해주세요')
    }
  };


  useEffect(function(){
  },[])

  return <PopupContainer onClickBackdrop={onClose}>
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">채널 등록</h4>
          <button onClick={() => onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <div className="modal-body">
          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">채널명</label>
            <div className="col-sm-9">
              <input ref={titleRef} type="text" className="form-control" placeholder="채널 명"/>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">채널 URL</label>
            <div className="col-sm-9">
              <input ref={urlRef} type="text" className="form-control" placeholder="URL 입력"/>
            </div>
          </div>
        </div>
        <div className="modal-footer justify-content-between">
          <button type="button" className="btn btn-primary" onClick={handleCreate}>등록하기</button>
        </div>
      </div>
    </div>
  </PopupContainer>
}
export default AddChannelPopup;
