import React, {useEffect, useState} from 'react';
import {ContentHeader} from '@components';
import {useAppSelector} from "@store/hooks";
import AddChannelPopup from "@app/components/popup/AddChannelPopup";
import AddChanelPopup from "@app/components/popup/AddChannelPopup";
import AddRestaurantPopup from "@app/components/popup/AddRestaurantPopup";
import AdminAPI from "@app/api/admin";
import moment from "moment";
import RestaurantInfoPopup from "@app/components/popup/RestaurantInfoPopup";

const RestaurantList = () => {
  const {token} = useAppSelector(state => state.auth);
  const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false);
  const [listData, setListData] = useState<any[]>([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState<number>(-1);
  const [dataTotalCount, setDataTotalCount] = useState(0)
  const [rowCount, setRowCount] = useState(30)
  const [refresh, setRefresh] = useState(new Date().getTime())
  const [paginationData, setPaginationData] = useState({
    currentPage: 0,
    maxPage: 0,
    paginationMin: 0,
    paginationMax: 0,
  })
  const [selectedChannel, setSelectedChannel] = useState<number>(-1)
  const [channelList, setChannelList] = useState<any[]>([])
  useEffect(() => {
    AdminAPI.getRestaurantList({page: paginationData.currentPage, channelId: selectedChannel}).then((res) => {
      setListData(res.data.list);
      setDataTotalCount(res.data.count)
      const maxPage = Math.ceil(res.data.count / rowCount)
      const paginationMin = Math.floor(paginationData.currentPage / 10) * 10
      const paginationMax = Math.min(paginationMin + 10, maxPage)
      setPaginationData({
        currentPage: paginationData.currentPage,
        maxPage: maxPage,
        paginationMin: paginationMin,
        paginationMax: paginationMax,
      })
    })
    AdminAPI.getChannelListAll().then((res) => {
      setChannelList(res.data.list)
    })
  }, [paginationData.currentPage, rowCount, refresh, selectedChannel])

  const handleDeleteRestaurant = (id: number) => {
    if (confirm('정말 삭제하시겠습니까?')) {
      AdminAPI.deleteRestaurant({id}).then((res) => {
        if (res.success) {
          alert('삭제되었습니다')
        }
        setRefresh(new Date().getTime())
      })
    }
  }
  return (
    <div>
      {showCreatePopup && <AddRestaurantPopup onClose={() => {
        setShowCreatePopup(false)
        setRefresh(new Date().getTime())
      }}/>}
      {(selectedRestaurant > 0) &&
        <RestaurantInfoPopup onClose={() => setSelectedRestaurant(-1)} restaurantId={selectedRestaurant}/>}
      <ContentHeader title="맛집 목록"/>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">맛집 목록</h3>
                  <div className="card-tools">
                    <div className='row'>
                      <div className="input-group input-group-sm" style={{
                        width: 150
                      }}>
                        <select className="custom-select rounded-0" id="exampleSelectRounded0" onChange={event => {
                          setSelectedChannel(Number(event.target.value))
                        }}>
                          <option value={-1}>전체</option>
                          {
                            channelList.map((item) => {
                              return <option key={item.id} value={item.id}>{item.name}</option>
                            })
                          }
                        </select>
                      </div>
                      <div className="input-group input-group-sm" style={{
                        width: 150
                      }}>
                        <input type="text" name="table_search" className="form-control float-right"
                               placeholder="Search"/>
                        <div className="input-group-append">
                          <button type="submit" className="btn btn-default">
                            <i className="fas fa-search"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-body table-responsive p-0" style={{
                  height: 700
                }}>
                  <table className="table table-head-fixed text-nowrap">
                    <thead>
                    <tr>
                      <th>ID</th>
                      <th>썸네일</th>
                      <th>Name</th>
                      <th>주소</th>
                      <th>포스트썸네일</th>
                      <th>상태</th>
                      <th>AddedAt</th>
                      <th>
                        <button type="button" className="btn btn-block btn-primary btn-sm"
                                onClick={() => setShowCreatePopup(true)}>추가
                        </button>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      listData.map((item, index) => {
                        return <tr key={index} onClick={() => {
                          setSelectedRestaurant(item.id)
                        }} style={{
                          cursor: 'pointer'
                        }}>
                          <td>{item.id}</td>
                          <td>
                            {
                              item.thumbnailUrl === null || item.thumbnailUrl.length === 0 ?
                                <div>썸네일 없음</div> :
                                <img src={item.thumbnailUrl} style={{
                                  width: 80,
                                }}/>
                            }
                          </td>
                          <td>[{item.category}] {item.name}</td>
                          <td>{item.address}</td>
                          <td>
                            {
                              item.postThumbnailUrl === null || item.postThumbnailUrl.length === 0 ?
                                <div>썸네일 없음</div> :
                                <img src={item.postThumbnailUrl} onClick={() => {
                                  window.open(item.postUrl)
                                }}
                                     style={{
                                       cursor: 'pointer',
                                       width: 80,
                                     }}/>
                            }
                          </td>
                          <td>
                            {item.status === 'wait' && <div className='badge badge-secondary'>대기</div>}
                            {item.status === 'success' && <div className='badge badge-primary'>성공</div>}
                            {item.status === 'fail' && <div className='badge badge-danger'>실패</div>}
                          </td>
                          <td>{moment(item.createdAt).add(9, 'hours').format("YYYY-MM-DD HH:mm:ss")}</td>
                          <td>
                            <button type="button" className="btn btn-block btn-danger btn-sm"
                                    onClick={() => handleDeleteRestaurant(item.id)}>삭제
                            </button>
                          </td>
                        </tr>
                      })
                    }
                    </tbody>
                  </table>
                </div>
                <div className="card-footer clearfix">
                  <ul className="pagination pagination-sm m-0 float-left">
                    <li className="page-item">
                      <div className="page-link no-hover">
                        표시
                      </div>
                    </li>
                    {
                      [30].map((item, index) => {
                        return <li className="page-item" key={index}>
                          <div className={"page-link " + (item === rowCount ? 'selected' : '')} style={{
                            cursor: 'pointer'
                          }} onClick={() => setRowCount(item)}>{item}</div>
                        </li>
                      })
                    }
                  </ul>
                  <ul className="pagination pagination-sm m-0 float-right">
                    <li className="page-item">
                      <div className={"page-link " + (paginationData.paginationMin < 10 ? 'disabled' : '')}
                           onClick={() => {
                             if (paginationData.paginationMin < 10) {
                               return;
                             } else {
                               setPaginationData({
                                 ...paginationData,
                                 currentPage: paginationData.paginationMin - 1
                               })
                             }
                           }}>
                        <i className="fas fa-chevron-left"></i>
                      </div>
                    </li>
                    {
                      Array(paginationData.paginationMax - paginationData.paginationMin).fill(0).map((item, index) => {
                        return <li className="page-item" key={index}>
                          <div
                            className={"page-link " + (index + paginationData.paginationMin === paginationData.currentPage ? 'selected' : '')}
                            style={{cursor: 'pointer'}}
                            onClick={() => setPaginationData({
                              ...paginationData,
                              currentPage: index + paginationData.paginationMin
                            })}>{index + paginationData.paginationMin + 1}</div>
                        </li>
                      })
                    }
                    <li className="page-item">
                      <div
                        className={"page-link " + (paginationData.paginationMax * rowCount > dataTotalCount ? 'disabled' : '')}
                        onClick={() => {
                          setPaginationData({
                            ...paginationData,
                            currentPage: paginationData.paginationMax
                          })
                        }}>
                        <i className="fas fa-chevron-right"></i>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RestaurantList;
