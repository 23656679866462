import React, {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useFormik} from 'formik';

import {loginUser} from '@store/reducers/auth';
import {setWindowClass} from '@app/utils/helpers';
import {PfButton, PfCheckbox} from '@profabric/react-components';

import * as Yup from 'yup';
import {sha256} from "js-sha256";
import {Form, InputGroup} from 'react-bootstrap';
import * as AuthService from '../../services/auth';
import AdminAPI from "@app/api/admin";

const Login = () => {
  const [isAuthLoading, setAuthLoading] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();


  const handleLogin = async function (id: string, password: string) {
    const res = await AdminAPI.login({id, password: sha256(password)})
    if (!res.success) {
      setAuthLoading(false);
      toast.error('Login Failed');
    }else{
      setAuthLoading(true);
      toast.success('Login is succeed!');
      setAuthLoading(false);
      dispatch(loginUser(res.data.access_token));
      navigate('/');
    }
  }

  const {handleChange, values, handleSubmit, touched, errors} = useFormik({
    initialValues: {
      id: '',
      password: ''
    },
    validationSchema: Yup.object({
      id: Yup.string()
        .min(1, 'Must be 1 characters or more')
        .max(30, 'Must be 30 characters or less')
        .required('Required'),
      password: Yup.string()
        .min(1, 'Must be 1 characters or more')
        .max(30, 'Must be 30 characters or less')
        .required('Required')
    }),
    onSubmit: (values) => {
      handleLogin(values.id, values.password);
    }
  });

  setWindowClass('hold-transition login-page');

  return (
    <div className="login-box">
      <div className="card card-outline card-primary">
        <div className="card-header text-center">
          <Link to="/" className="h1">
            <b>Admin</b>
            <span>LTE</span>
          </Link>
        </div>
        <div className="card-body">
          <p className="login-box-msg">로그인</p>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <InputGroup className="mb-3">
                <Form.Control
                  id="id"
                  name="id"
                  type="text"
                  placeholder="ID"
                  onChange={handleChange}
                  value={values.id}
                  isValid={touched.id && !errors.id}
                  isInvalid={touched.id && !!errors.id}
                />
                {touched.id && errors.id ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.id}
                  </Form.Control.Feedback>
                ) : (
                  <InputGroup.Append>
                    <InputGroup.Text>
                      <i className="fas fa-envelope" />
                    </InputGroup.Text>
                  </InputGroup.Append>
                )}
              </InputGroup>
            </div>
            <div className="mb-3">
              <InputGroup className="mb-3">
                <Form.Control
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Password"
                  onChange={handleChange}
                  value={values.password}
                  isValid={touched.password && !errors.password}
                  isInvalid={touched.password && !!errors.password}
                />
                {touched.password && errors.password ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                ) : (
                  <InputGroup.Append>
                    <InputGroup.Text>
                      <i className="fas fa-lock" />
                    </InputGroup.Text>
                  </InputGroup.Append>
                )}
              </InputGroup>
            </div>

            <div className="row">
              <div className="col-8">
                {/*<PfCheckbox checked={false}>
                  로그인 정보 저장하기
                </PfCheckbox>*/}
              </div>
              <div className="col-4">
                <PfButton
                  block
                  type="submit"
                  loading={isAuthLoading}
                >
                  로그인
                </PfButton>
              </div>
            </div>
          </form>

        </div>
      </div>
    </div>
  );
};

export default Login;
